import React, { type PropsWithChildren, useState } from 'react';
import throttle from 'lodash/throttle';
import { Box, xcss } from '@atlaskit/primitives';
import { WidthObserver } from '@atlaskit/width-detector';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	LARGE_CONTAINER_WIDTH,
	MEDIUM_CONTAINER_WIDTH,
	SMALL_CONTAINER_WIDTH,
} from './constants.tsx';

export const FilterBar = ({ children }: PropsWithChildren) => {
	const [containerWidth, setContainerWidth] = useState<number>(900);

	const handleWidth = throttle(setContainerWidth, 50);

	return (
		<Box xcss={relativeWrapperStyles}>
			<WidthObserver setWidth={handleWidth} />
			<Box
				xcss={[
					filterBarSharedStyles,
					isVisualRefreshEnabled() && filterBarSharedStylesVisualRefresh,
					containerWidth > LARGE_CONTAINER_WIDTH && filterBarLargeStyles,
					containerWidth <= LARGE_CONTAINER_WIDTH &&
						containerWidth > MEDIUM_CONTAINER_WIDTH &&
						filterBarMidStyles,
					containerWidth <= MEDIUM_CONTAINER_WIDTH &&
						containerWidth > SMALL_CONTAINER_WIDTH &&
						filterBarSmallStyles,
				]}
			>
				{children}
			</Box>
		</Box>
	);
};

const relativeWrapperStyles = xcss({
	position: 'relative',
});

const filterBarSharedStyles = xcss({
	width: '100%',
	display: 'grid',
	columnGap: 'space.200',
	rowGap: 'space.100',
	alignItems: 'flex-end',
});

const filterBarSharedStylesVisualRefresh = xcss({
	columnGap: 'space.100',
});

const filterBarSmallStyles = xcss({
	gridTemplateColumns: 'repeat(2, 1fr)',
});

const filterBarMidStyles = xcss({
	gridTemplateColumns: 'repeat(3, 1fr)',
});

const filterBarLargeStyles = xcss({
	display: 'flex',
	flexWrap: 'wrap',
});
